import banners from '../../../asset/banner/banner';
import { Slide } from 'react-slideshow-image';
import classNames from 'classnames/bind';
import styles from './Banner.module.scss';
const cx = classNames.bind(styles);

function Banner() {
    return (
        <div className={cx('wrapper')}>
            <Slide arrows={false} duration={2500}>
                <img className={cx('banner')} src={banners.banner6} alt="6" />
                <img className={cx('banner')} src={banners.banner1} alt="1" />
                <img className={cx('banner')} src={banners.banner2} alt="2" />
                <img className={cx('banner')} src={banners.banner3} alt="3" />
                <img className={cx('banner')} src={banners.banner4} alt="4" />
                <img className={cx('banner')} src={banners.banner5} alt="5" />
            </Slide>
        </div>
    );
}

export default Banner;
