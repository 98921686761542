import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import classNames from 'classnames/bind';

import styles from './Write.module.scss';
import Button from '../../Button/button';
import * as toast from '../../../utils/toast';
import * as apiService from '../../../services/apiService';
import config from '../../../config';
import UploadImage from '../../UploadImage/uploadImage';
import CustomCombobox from '../../CustomCombobox/customCombobox';
import { categoryData, typeData } from './utils';
import { isLogged } from '../../../services/cookieService';
import Cookies from 'js-cookie';
import { DEFAULTPOSTIMG } from '../../../config/path';
import Loading from '../../Loading/loading';

const cx = classNames.bind(styles);
function Write() {
    const navigator = useNavigate();
    const [title, setTitle] = useState('');
    const [shortTitle, setShortTitle] = useState('');
    const [category, setCategory] = useState('');
    const [type, setType] = useState('new');
    const [thumbnail, setThumbnail] = useState();
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (!isLogged()) navigator('/auth/login');

        const markdown = draftToMarkdown(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(markdown);
    }, [editorState]);

    const handlePreview = () => {
        toast.error('Tính năng ni t chưa làm, publish rồi có gì edit đi =))');
    };
    const handlePublish = () => {
        const token = Cookies.get('token');
        if (!token) toast.error('Please re-login first');
        else if (title === '' || convertedContent === '') {
            toast.error('Title or content could not be empty');
        } else {
            const fetchAPI = async () => {
                setIsSaving(true);
                var fileUploaded;
                if (thumbnail) {
                    const fileUpload = await apiService.uploadFile(
                        config.path.UPLOADFILE,
                        'file',
                        thumbnail,
                        Cookies.get('token'),
                    );
                    fileUploaded = fileUpload.data.content;
                } else {
                    fileUploaded = DEFAULTPOSTIMG;
                }

                const res = await apiService.postJson(
                    config.path.SAVE_POST,
                    {
                        title,
                        category,
                        shortTitle,
                        type,
                        thumbnail: fileUploaded,
                        content: convertedContent,
                    },
                    token,
                );
                setIsSaving(false);
                if (res.status === 200 || res.status === 201) {
                    navigator(`/post/${res.content.transliterated}/${res.content.id}`);
                } else if (res.status === 401) {
                    navigator('/auth/login');
                } else if (res.data && res.data.errors) {
                    toast.error(res.data.errors[0]);
                } else {
                    toast.error('Unknown error: You can not pushlish this post');
                }
            };
            if (token) fetchAPI();
            else toast.noLogin();
        }
    };
    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };
    const handleTypeChange = (e) => {
        setType(e.target.value);
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('title')}>
                <input value={title} type="text" placeholder="Title" onChange={(e) => setTitle(e.target.value)}></input>
                <Button
                    className={cx('button')}
                    type="outline"
                    size="medium"
                    fontSize={cx('font-small')}
                    onClick={handlePreview}
                >
                    Preview
                </Button>
                <Button
                    className={cx('button')}
                    type="outline"
                    size="medium"
                    fontSize={cx('font-small')}
                    onClick={handlePublish}
                >
                    Publish
                </Button>
            </div>
            <div className={cx('short-title')}>
                <input
                    value={shortTitle}
                    type="text"
                    placeholder="Short title"
                    onChange={(e) => setShortTitle(e.target.value)}
                ></input>
            </div>
            <CustomCombobox
                data={categoryData}
                text="Select a category"
                handleCategoryChange={handleCategoryChange}
            ></CustomCombobox>
            <CustomCombobox
                data={typeData}
                text="Select a post type"
                handleTypeChange={handleTypeChange}
            ></CustomCombobox>

            <UploadImage thumbnail={thumbnail} setThumbnail={setThumbnail} title="Tải lên ảnh đại diện"></UploadImage>
            <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName={cx('wrapper-class')}
                editorClassName={cx('editor-class')}
                toolbarClassName={cx('toolbar-class')}
            />
            {isSaving && <Loading></Loading>}
        </div>
    );
}

export default Write;
