import Header from '../components/Header/header';
import styles from './DefaultLayout.module.scss';
import className from 'classnames/bind';
import Footer from '../components/Footer';
import TopBar from '../components/TopBar/topBar';
import Banner from '../components/Banner/banner';
import ChatButton from '../components/ChatButton/chatButton';

const cx = className.bind(styles);

function DefaultLayout({ children }) {
    return (
        <div className={cx('wrapper')}>
            <TopBar />
            <Header />
            <Banner />
            <div className={cx('container')}>{children}</div>
            <ChatButton></ChatButton>
            <Footer />
        </div>
    );
}

export default DefaultLayout;
