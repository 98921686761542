import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faFacebook, faGithub, faGoogle } from '@fortawesome/free-brands-svg-icons';

import classNames from 'classnames/bind';

import styles from './LoginRegister.module.scss';
import Button from '../../Button/button';
import images from '../../../asset/images';
import * as apiService from '../../../services/apiService';
import Input from '../../Input/input';
import config from '../../../config';
import { useStore, action } from '../../../storage';
import * as plogToast from '../../../utils/toast';
import Image from '../../Image/image';
import Loading from '../../Loading/loading';
const cx = classNames.bind(styles);

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLogining, setIsLogining] = useState(false);
    const [store, dispatch] = useStore();
    const navigate = useNavigate();

    useEffect(() => {
        const listener = (event) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
                submitLogin();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [username, password]);

    const submitLogin = () => {
        const fetchAPI = async () => {
            setIsLogining(true);
            const response = await apiService.postJson(config.path.LOGIN, { username, password });
            setIsLogining(false);
            if (response.errors && response.errors.length === 0) {
                dispatch(action.setLoggedUser(response.content));
                navigate(-1);
            } else {
                plogToast.error('Login failed: ' + response.data.errors);
            }
        };
        if (username === '' || password === '') {
            plogToast.error('Please input full information');
        } else fetchAPI();
    };

    return (
        <div className={cx('wrapper')}>
            <Image to={config.routes.home} className={cx('logo')} src={images.logo} alt="PLog" />
            <Input
                className={cx('username')}
                value={username}
                type="text"
                placeholder="User name"
                spellCheck={false}
                onChange={(e) => setUsername(e.target.value)}
                leftIcon={<FontAwesomeIcon icon={faUser} />}
            />
            <Input
                className={cx('password')}
                value={password}
                type="password"
                placeholder="Password"
                spellCheck={false}
                onChange={(e) => setPassword(e.target.value)}
                leftIcon={<FontAwesomeIcon icon={faLock} />}
            />
            <Button onClick={submitLogin} className={cx('submit-btn')} size="large">
                Login
            </Button>
            <div className={cx('navigate')}>
                <Link to={config.routes.forgotPassword}>
                    <h5>Forgot password?</h5>
                </Link>
                <Link to={config.routes.register}>
                    <h5>Sign up</h5>
                </Link>
            </div>
            {isLogining && <Loading></Loading>}
        </div>
    );
};

export default Login;
