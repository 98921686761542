import Home from '../Components/pages/Home/home';
import PostView from '../Components/pages/PostView/PostView';
import Write from '../Components/pages/Write/write';
import { HeaderOnly } from '../layouts';
import Search from '../layouts/components/Search/search';
import config from '../config';
import Login from '../Components/pages/LoginRegister/login';
import EditPost from '../Components/pages/EditPost/editPost';
import NotFound from '../Components/pages/NotFound/notFound';
import File from '../Components/pages/File/file';
const publicRoutes = [
    { path: config.routes.home, component: Home },
    { path: config.routes.post, component: PostView },
    { path: config.routes.search, component: Search, layout: null },
    { path: config.routes.edit, component: EditPost, layout: HeaderOnly },
    { path: config.routes.write, component: Write, layout: HeaderOnly },
    { path: config.routes.fileUpload, component: File, layout: HeaderOnly },
    { path: config.routes.login, component: Login, layout: null },
    { path: '*', component: NotFound, layout: null },
];
// require login
const privateRoutes = [];

export { publicRoutes, privateRoutes };
