import classNames from 'classnames/bind';
import styles from './Sidebar.module.scss';

const cx = classNames.bind(styles);

function Sidebar({ className, toc, content, bestUser = true }) {
    const classes = cx('wrapper', { [className]: className });

    return (
        <aside className={classes}>
            {toc && (
                <div className={cx('section')}>
                    <h3 className={cx('section-title')}>MỤC LỤC</h3>
                    {toc}
                </div>
            )}
            <div className={cx('section')}>{content}</div>
        </aside>
    );
}

export default Sidebar;
