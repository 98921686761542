import styles from './Footer.module.scss';
import classNames from 'classnames/bind';
import 'tippy.js/dist/tippy.css';

const cx = classNames.bind(styles);

function Footer() {
    return (
        <footer className={cx('footer')}>
            <div className={cx('wrapper')}>
                <div className={cx('main-block')}>
                    <h4>THÔNG TIN LIÊN HỆ</h4>
                    <h5>Công ty TNHH tư vấn Lạc Việt Quality</h5>
                    <span>Trụ sở: </span>
                    <span>Hotline: 037 340 1916 - 033 811 4419</span>
                    <span>Email: lacviet.quality@gmail.com</span>
                </div>
                <div className={cx('main-block')}>
                    <h4>FANPAGE FACEBOOK</h4>
                    <iframe
                        className={cx('wiget')}
                        title="facebook"
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100091599616364&tabs=timeline%2C%20messenger&width=500&height=300&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                </div>
                <div className={cx('main-block')}>
                    <h4>GOOGLE MAP</h4>
                    <iframe
                        className={cx('wiget')}
                        title="googlemap"
                        src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d479.16775255575334!2d108.2501533!3d16.0995987!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1svi!2s!4v1691467061733!5m2!1svi!2s"
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
