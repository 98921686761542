import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import images from '../../../asset/images';

const CONTACT_ITEMS = [
    {
        title: 'Chat Messenger',
        icon: <img src={images.messenger} style={{ width: '22px', height: '22px', objectFit: 'cover' }} alt="mess" />,
    },
    {
        title: 'Nhắn qua zalo',
        icon: <img src={images.zalo} style={{ width: '22px', height: '22px', objectFit: 'cover' }} alt="zalo" />,
    },
];

export { CONTACT_ITEMS };
