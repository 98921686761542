import { useState } from 'react';

import classNames from 'classnames/bind';

import styles from './UploadFile.module.scss';
import Button from '../../Button/button';
import * as toast from '../../../utils/toast';
import * as apiService from '../../../services/apiService';
import config from '../../../config';
import UploadImage from '../../UploadImage/uploadImage';
import Cookies from 'js-cookie';
import LogoItem from '../../LogoItem/logoItem';

const cx = classNames.bind(styles);
function File() {
    const [fileUpload, setFileUpload] = useState();
    const [listFiles, setListFiles] = useState([]);
    const [fileDelete, setFileDelete] = useState('');
    const [uploadedFileLink, setUploadedFileLink] = useState();

    const handleUpload = () => {
        const token = Cookies.get('token');
        if (!token) toast.error('Please re-login first');
        else {
            const fetchAPI = async () => {
                if (fileUpload) {
                    const uploadAPI = await apiService.uploadFile(
                        config.path.UPLOADFILE,
                        'file',
                        fileUpload,
                        Cookies.get('token'),
                    );
                    console.log(uploadAPI);
                    setUploadedFileLink(config.path.FILE_STORAGE + uploadAPI.data.content);
                }
            };
            if (token) fetchAPI();
            else toast.noLogin();
        }
    };

    const handleLoadAll = () => {
        const token = Cookies.get('token');
        if (!token) toast.error('Please re-login first');
        else {
            const fetchAPI = async () => {
                const fetchAllFiles = await apiService.fetchAll(config.path.LOADALLFILES);
                setListFiles(fetchAllFiles);
            };
            if (token) fetchAPI();
            else toast.noLogin();
        }
    };

    const handleDelete = () => {
        const token = Cookies.get('token');
        if (!token) toast.error('Please re-login first');
        else {
            const fetchAPI = async () => {
                if (fileDelete) {
                    const deleteAPI = await apiService.deleteFile(
                        config.path.DELETEFILE,
                        {
                            filename: fileDelete,
                        },
                        Cookies.get('token'),
                    );
                    toast.info(deleteAPI);
                }
            };
            if (token) fetchAPI();
            else toast.noLogin();
        }
    };

    return (
        <div className={cx('wrapper')}>
            <UploadImage thumbnail={fileUpload} setThumbnail={setFileUpload} title="Tải lên ảnh đại diện"></UploadImage>
            <Button
                className={cx('button')}
                type="outline"
                size="medium"
                fontSize={cx('font-small')}
                onClick={handleUpload}
            >
                Upload
            </Button>
            <div>{uploadedFileLink}</div>
            <div className={cx('title')}>
                <input
                    value={fileDelete}
                    type="text"
                    placeholder="Input file name to delete"
                    onChange={(e) => setFileDelete(e.target.value)}
                ></input>
                <Button
                    className={cx('button')}
                    type="outline"
                    size="medium"
                    fontSize={cx('font-small')}
                    onClick={handleDelete}
                >
                    Delete
                </Button>
            </div>
            <Button
                className={cx('button')}
                type="outline"
                size="medium"
                fontSize={cx('font-small')}
                onClick={handleLoadAll}
            >
                Load All Files
            </Button>
            <div>
                {listFiles.map((fileName) => {
                    return (
                        <LogoItem
                            key={fileName}
                            className={cx('logo')}
                            image={config.path.FILE_STORAGE + fileName}
                            text={fileName}
                            background="none"
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default File;
