import classNames from 'classnames/bind';
import styles from './Header.module.scss';
import images from '../../../asset/images';
import Button from '../../../Components/Button/button';
import Menu from '../../../Components/Popper/Menu';

import Search from '../Search/search';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { MENU_ITEMS } from './utils';

const cx = classNames.bind(styles);

function Header() {
    const navigate = useNavigate();
    const scrollToClass = (className) => {
        var element = document.querySelector(className);
        if (element != null) {
            var headerOffset = 60;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };
    const handleInfo = async () => {
        await navigate('/');
        scrollToClass('.tin-tuc');
    };
    const handleIntro = async () => {
        await navigate('/');
        scrollToClass('.gioi-thieu');
    };
    const handleHome = async () => {
        await navigate('/');
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    // Handle logic
    const handleMenuChange = (menuItem) => {
        switch (menuItem.title) {
            case 'Trang chủ':
                handleHome();
                break;
            case 'Tin tức':
                handleInfo();
                break;
            case 'Giới thiệu':
                handleIntro();
                break;
            default:
                break;
        }
    };
    return (
        <header className={cx('wrapper')}>
            <div className={cx('inner')}>
                <div className={cx('header-menu')}>
                    <Link to={'/'}>
                        <img className={cx('logo')} src={images.logo} alt="PLog" />
                    </Link>
                    <div className={cx('medium')}>
                        <Button size="medium" type="text-light" to="/" onClick={handleHome}>
                            Trang chủ
                        </Button>
                        <Button size="medium" type="text-light" onClick={handleIntro}>
                            Giới thiệu
                        </Button>
                        <Button size="medium" type="text-light">
                            Dịch vụ
                        </Button>
                        <Button size="medium" type="text-light" onClick={handleInfo}>
                            Tin tức
                        </Button>
                        <Button size="medium" type="text-light">
                            Tài liệu
                        </Button>
                        <Button size="medium" type="text-light">
                            Đăng ký dịch vụ
                        </Button>
                    </div>
                </div>
                <div className={cx('right-block')}>
                    <Search />
                    <Menu items={MENU_ITEMS} onchange={handleMenuChange}>
                        <FontAwesomeIcon className={cx('un-medium')} icon={faBars} style={{ color: '#9b9b9b' }} />
                    </Menu>
                </div>
            </div>
        </header>
    );
}

export default Header;
