import Button from '../../../Components/Button/button';
import classNames from 'classnames/bind';
import styles from './TopBar.module.scss';
import { faClock, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faSquareFacebook } from '@fortawesome/free-brands-svg-icons';

const cx = classNames.bind(styles);
function TopBar() {
    return (
        <div className={cx('wrapper')}>
            <Button
                size="medium"
                type="text-light"
                fontSize="font-small"
                leftIcon={<FontAwesomeIcon icon={faEnvelope} />}
            >
                lacviet.quality@gmail.com
            </Button>
            <Button size="medium" type="text-light" fontSize="font-small" leftIcon={<FontAwesomeIcon icon={faClock} />}>
                7:30 - 17:00
            </Button>
            <Button size="medium" type="text-light" fontSize="font-small" leftIcon={<FontAwesomeIcon icon={faPhone} />}>
                037 340 1916 - 033 811 4419
            </Button>
            <Button
                to="https://www.facebook.com/profile.php?id=100091599616364&ref=embed_page"
                size="medium"
                type="text-light"
                fontSize="font-small"
                leftIcon={<FontAwesomeIcon icon={faFacebook} />}
            >
                Facebook
            </Button>
        </div>
    );
}

export default TopBar;
