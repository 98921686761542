import { useState, useEffect } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import classNames from 'classnames/bind';

import { useStore } from '../../../storage';
import styles from './EditPost.module.scss';
import Button from '../../Button/button';
import * as toast from '../../../utils/toast';
import * as apiService from '../../../services/apiService';
import config from '../../../config';
import { useNavigate, useParams } from 'react-router-dom';
import { isLogged } from '../../../services/cookieService';
import Cookies from 'js-cookie';
import CustomCombobox from '../../CustomCombobox/customCombobox';
import UploadImage from '../../UploadImage/uploadImage';
import { categoryData, typeData } from '../Write/utils';
import Loading from '../../Loading/loading';

const cx = classNames.bind(styles);
function EditPost() {
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [shortTitle, setShortTitle] = useState('');
    const [type, setType] = useState('new');
    const [postImage, setPostImage] = useState('');
    const [category, setCategory] = useState('');
    const [thumbnail, setThumbnail] = useState();
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const navigate = useNavigate();

    const [store, dispatch] = useStore();

    useEffect(() => {
        const fetchAPI = async () => {
            setIsFetching(true);
            const resPost = await apiService.fetch(config.path.GET_POST, {
                params: {
                    id,
                },
            });

            if (!resPost || resPost.error) navigate('/404');
            document.title = 'Editing: ' + resPost.title;
            setIsFetching(false);
            setEditorState(EditorState.createWithContent(ContentState.createFromText(resPost.content)));
            setTitle(resPost.title);
            setCategory(resPost.category);
        };
        fetchAPI();
    }, [id]);

    useEffect(() => {
        const markdown = draftToMarkdown(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(markdown);
    }, [editorState]);

    const handlePreview = () => {};
    const handlePublish = () => {
        const token = Cookies.get('token');
        if (!token) toast.error('Please re-login first');
        else if (title === '' || convertedContent === '') {
            toast.error('Title or content could not be empty');
        } else {
            const fetchAPI = async () => {
                var fileUploaded;
                setIsSaving(true);
                if (thumbnail) {
                    const fileUpload = await apiService.uploadFile(
                        config.path.UPLOADFILE,
                        'file',
                        thumbnail,
                        Cookies.get('token'),
                    );
                    fileUploaded = fileUpload.data.content;
                } else {
                    fileUploaded = postImage;
                }
                const res = await apiService.update(
                    config.path.UPDATE_POST,
                    {
                        id,
                        title,
                        shortTitle,
                        type,
                        thumbnail: fileUploaded,
                        content: convertedContent,
                        category,
                    },
                    token,
                );
                setIsSaving(false);
                if (res.status === 200 || res.status === 201) {
                    navigate(`/post/${res.content.transliterated}/${res.content.id}`);
                } else if (res.status === 401) {
                    navigate('/auth/login');
                } else if (res.data && res.data.errors) {
                    toast.error(res.data.errors[0]);
                } else {
                    toast.error('Unknown error: You can not pushlish this post');
                }
            };
            if (isLogged()) fetchAPI();
            else toast.noLogin();
        }
    };
    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };
    const handleTypeChange = (e) => {
        setType(e.target.value);
    };
    return (
        <div className={cx('wrapper')}>
            {!isFetching && (
                <div className={cx('title')}>
                    <input
                        value={title}
                        type="text"
                        placeholder="Title"
                        onChange={(e) => setTitle(e.target.value)}
                    ></input>
                    <Button
                        className={cx('button')}
                        type="outline"
                        size="medium"
                        fontSize={cx('font-small')}
                        onClick={handlePreview}
                    >
                        Preview
                    </Button>
                    <Button
                        className={cx('button')}
                        type="outline"
                        size="medium"
                        fontSize={cx('font-small')}
                        onClick={handlePublish}
                    >
                        Publish
                    </Button>
                </div>
            )}
            <div className={cx('short-title')}>
                <input
                    value={shortTitle}
                    type="text"
                    placeholder="Title"
                    onChange={(e) => setShortTitle(e.target.value)}
                ></input>
            </div>
            <CustomCombobox
                data={categoryData}
                text="Select a category"
                handleCategoryChange={handleCategoryChange}
            ></CustomCombobox>
            <CustomCombobox
                data={typeData}
                text="Select a post type"
                handleTypeChange={handleTypeChange}
            ></CustomCombobox>

            <UploadImage thumbnail={thumbnail} setThumbnail={setThumbnail} title="Tải lên ảnh đại diện"></UploadImage>
            {!isFetching && (
                <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    wrapperClassName={cx('wrapper-class')}
                    editorClassName={cx('editor-class')}
                    toolbarClassName={cx('toolbar-class')}
                />
            )}
            {isSaving && <Loading></Loading>}
        </div>
    );
}

export default EditPost;
