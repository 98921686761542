const categoryData = [
    { id: 1, value: 'htql', description: 'Hệ thống quản lí' },
    { id: 2, value: 'htqlAppt', description: 'Hệ thống quản lí ATTP' },
    { id: 3, value: 'nongNghiepSach', description: 'Nông nghiệp sạch - bền vững' },
    { id: 4, value: 'tinTuc', description: 'Tin tức chuyên ngành' },
    { id: 5, value: 'dichVu', description: 'Dịch vụ khác' },
];
const typeData = [
    { id: 1, value: 'defaultPost', description: 'Default' },
    { id: 2, value: 'newPost', description: 'New' },
];

export { categoryData, typeData };
