const routes = {
    home: '/',
    post: '/post/:transliterated/:id',
    edit: '/post/edit/:transliterated/:id',
    search: '/search',
    write: '/write',
    fileUpload: '/file',
    login: '/auth/login',
    forgotPassword: '/auth/forgotPassword',
};

export default routes;
