import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './UploadImage.module.scss';

const cx = classNames.bind(styles);
function UploadImage({ title = 'Upload image', thumbnail, setThumbnail }) {
    return (
        <div className={cx('wrapper')}>
            <h4>{title}</h4>
            {thumbnail && (
                <div>
                    <img alt="not found" width={'250px'} src={URL.createObjectURL(thumbnail)} />
                    <br />
                    <button onClick={() => setThumbnail(null)}>Remove</button>
                </div>
            )}
            <input
                type="file"
                name="myImage"
                onChange={(event) => {
                    setThumbnail(event.target.files[0]);
                }}
            />
        </div>
    );
}

export default UploadImage;
