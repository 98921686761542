const images = {
    logo: require('./logo.png'),
    noImage: require('./no-image.png'),
    notFound: require('./notfound.gif'),
    tamnhin1: require('./tamnhin1.jpg'),
    tamnhin2: require('./tamnhin2.jpg'),
    plus: require('./plus.png'),
    whyChooseMe: require('./why-choose-me.jpg'),
    messenger: require('./messenger-icon.png'),
    zalo: require('./zalo-icon.png'),
    contactus: require('./contactus.png'),
};

export default images;
