import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import classNames from 'classnames/bind';
import StickyBox from 'react-sticky-box';
import * as toast from '../../../utils/toast';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import config from '../../../config';
import * as apiService from '../../../services/apiService';
import RenderMarkdown from '../../RenderMarkdown/renderMarkdown';
import styles from './PostView.module.scss';
import Sidebar from '../../../layouts/components/Sidebar/sidebar';
import TableOfContent from '../../TableOfContent';
import Loading from '../../Loading/loading';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Button from '../../Button/button';
import { isLogged } from '../../../services/cookieService';

const cx = classNames.bind(styles);
function PostView() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState({});
    const [isFetching, setIsFetching] = useState(true);
    const [logged, setLogged] = useState(false);

    useEffect(() => {
        setLogged(isLogged());
        const fetchAPI = async () => {
            setIsFetching(true);
            const resPost = await apiService.fetch(config.path.GET_POST, {
                params: {
                    id,
                },
            });
            if (!resPost || resPost.error) navigate('/404');
            else {
                document.title = resPost.title;
            }
            setIsFetching(false);
            setPost(resPost);
        };
        fetchAPI();
    }, [id]);

    const deletePost = () => {
        const fetchAPI = async () => {
            const token = Cookies.get('token');
            setIsFetching(true);

            const deletePost = await apiService.deleteAPI(
                config.path.DELETE_POST,
                {
                    postID: id,
                },
                token,
            );
            if (deletePost === 'OK' || deletePost.status === 200 || deletePost.status === 201) {
                navigate('/');
            } else {
                toast.error('Unknown error: Can not delete this post');
            }

            setIsFetching(false);
        };
        fetchAPI();
    };
    const confirmDelete = () => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deletePost(),
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                },
            ],
        });
    };
    return (
        <div className={cx('wrapper')}>
            {!isFetching && (
                <div className={cx('content')}>
                    <div className={cx('post-content')}>
                        <div className={cx('blog-title')}>
                            <h1>{post.title.toUpperCase()}</h1>
                            {logged && (
                                <div className={cx('action')}>
                                    <Button
                                        className={cx('edit-btn')}
                                        type="text-light"
                                        size="icon-btn"
                                        to={`/post/edit/${post.transliterated}/${post.id}`}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                    <Button
                                        className={cx('delete-btn')}
                                        type="text-light"
                                        size="icon-btn"
                                        onClick={confirmDelete}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </div>
                            )}
                        </div>
                        <RenderMarkdown markdownData={post.content}></RenderMarkdown>
                    </div>

                    <div className={cx('sidebar')}>
                        <StickyBox offsetTop={90}>
                            <Sidebar toc={<TableOfContent />}></Sidebar>
                        </StickyBox>
                    </div>
                </div>
            )}
            {isFetching && <Loading></Loading>}
        </div>
    );
}

export default PostView;
