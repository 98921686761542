import classNames from 'classnames/bind';
import styles from './ChatButton.module.scss';
import Menu from '../../../Components/Popper/Menu';
import { CONTACT_ITEMS } from './utils';
import images from '../../../asset/images';

const cx = classNames.bind(styles);

function ChatButton() {
    const handleMenuChange = (menuItem) => {
        switch (menuItem.title) {
            case 'Chat Messenger':
                window.open('https://www.facebook.com/profile.php?id=100091599616364&ref=embed_page');
                break;
            case 'Nhắn qua zalo':
                window.open('https://zalo.me/0373401916');
                break;
            default:
                break;
        }
    };
    return (
        <Menu
            className={cx('contactus-menu')}
            size="large"
            items={CONTACT_ITEMS}
            hideOnClick={true}
            onchange={handleMenuChange}
        >
            <button className={cx('contactus-btn', 'btn', 'btn-white', 'btn-animate')}>
                <img className={cx('contactus-img')} alt="contact-us" src={images.contactus} />
            </button>
        </Menu>
    );
}

export default ChatButton;
