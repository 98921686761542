const customers = {
    customer1: require('./1.png'),
    customer2: require('./2.png'),
    customer3: require('./3.png'),
    customer4: require('./4.png'),
    customer5: require('./5.png'),
    customer6: require('./6.png'),
    customer7: require('./7.png'),
    customer8: require('./8.png'),
    customer9: require('./9.png'),
    customer10: require('./10.png'),
};
export default customers;
