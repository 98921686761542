import * as http from '../utils/http';
import config from '../config';

export const search = async (path, keyword, type = 'less') => {
    try {
        const res = await http.get(path, {
            params: {
                keyword: keyword,
                type: type,
            },
        });
        return res.content;
    } catch (error) {}
};

export const fetch = async (path, param, token) => {
    try {
        const res = await http.get(path, param, token);
        return res.content;
    } catch (error) {
        return error.response.data;
    }
};
export const deleteAPI = async (path, param, token) => {
    try {
        const res = await http.deleteHttp(path, param, token);
        return res.content;
    } catch (error) {
        return error.response.data;
    }
};
export const fetchAll = async (path, token) => {
    try {
        const res = await http.get(path, token);
        return res.content;
    } catch (error) {
        return error.response.data;
    }
};

export const update = async (path, data, token) => {
    try {
        const res = await http.put(path, data, token);
        return res;
    } catch (error) {
        return error.response;
    }
};

export const postJson = async (path, data, token) => {
    try {
        const res = await http.post(path, data, token);
        return res;
    } catch (error) {
        return error.response;
    }
};

export const uploadFile = async (path, fileProp, file, token) => {
    try {
        const res = await http.uploadFile(path, fileProp, file, token);
        return res;
    } catch (error) {
        return error.response.data;
    }
};
export const deleteFile = async (path, param, token) => {
    try {
        const res = await http.deleteFile(path, param, token);
        return res;
    } catch (error) {
        return error;
    }
};
