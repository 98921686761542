const MENU_ITEMS = [
    {
        title: 'Trang chủ',
    },
    {
        title: 'Giới thiệu',
    },
    {
        title: 'Dịch vụ',
    },
    {
        title: 'Tin tức',
    },
    {
        title: 'Tài liệu',
    },
    {
        title: 'Đăng kí dịch vụ',
    },
];

export { MENU_ITEMS };
