import classNames from 'classnames/bind';
import { useState, forwardRef } from 'react';
import images from '../../asset/images';
import styles from './Image.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

const Image = forwardRef(
    ({ src, alt, className, fallBack: customFallBack = images.noImage, to, text, ...prop }, ref) => {
        const [fallBack, setFallBack] = useState('');

        const handleError = () => {
            setFallBack(customFallBack);
        };
        if (to) {
            return (
                <Link to={to} ref={ref}>
                    <img
                        className={cx('wrapper', className)}
                        src={fallBack || src}
                        alt={alt}
                        {...prop}
                        onError={handleError}
                    ></img>
                </Link>
            );
        } else {
            return (
                <img
                    className={cx('wrapper', className)}
                    ref={ref}
                    src={fallBack || src}
                    alt={alt}
                    {...prop}
                    onError={handleError}
                ></img>
            );
        }
    },
);

export default Image;
