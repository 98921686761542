import classNames from 'classnames/bind';
import styles from './PostItem.module.scss';
import { Link } from 'react-router-dom';
import config from '../../config';
import Image from '../Image/image';

const cx = classNames.bind(styles);

function PostItem({ data, image, type, className }) {
    const classes = cx('wrapper', type, { [className]: className });
    return (
        <div key={data.id} className={classes}>
            {image && <Image className={cx('avatar')} src={image} />}
            <div className={cx('info')}>
                <a href={'/blogger/' + data.user.username} className={cx('author')}>
                    {data.user.name}
                </a>
                <span className={cx('post-date')}>đăng vào lúc {data.createdAt}</span>

                <Link to={`/blog/${encodeURIComponent(data.transliterated)}/${data.id}`}>
                    <h4 className={cx('post-title')}>{data.title}</h4>
                    <p className={cx('short-content')}>{data.shortContent.slice(0, 200) + '...'}</p>
                </Link>
            </div>
        </div>
    );
}

export default PostItem;
