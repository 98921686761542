import axios from 'axios';

const http = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

export const get = async (path, options, token) => {
    const response = token
        ? await http({
              method: 'get',
              url: path,
              params: options,
              headers: { Authorization: `Bearer ${token}` },
          })
        : await http.get(path, options);
    return response.data;
};

export const post = async (path, data, token) => {
    const response = token
        ? await http({
              method: 'post',
              url: path,
              data: data,
              headers: { Authorization: `Bearer ${token}` },
          })
        : await http.post(path, data);
    return response.data;
};

export const put = async (path, data, token) => {
    const response = token
        ? await http({
              method: 'put',
              url: path,
              data: data,
              headers: { Authorization: `Bearer ${token}` },
          })
        : await http.put(path, data);
    return response.data;
};

export const deleteHttp = async (path, param, token) => {
    const response = token
        ? await http({
              method: 'delete',
              url: path,
              params: param,
              headers: { Authorization: `Bearer ${token}` },
          })
        : await http.delete(path, param);
    return response.data;
};

export const uploadFile = async (path, fileProp, file, token) => {
    const formData = new FormData();
    formData.append(fileProp, file);

    const response = await http({
        method: 'post',
        url: path,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    });

    return response;
};

export const deleteFile = async (path, param, token) => {
    const response = token
        ? await http({
              method: 'delete',
              url: path,
              params: param,
              headers: { Authorization: `Bearer ${token}` },
          })
        : await http.delete(path, param);
    return response.data;
};

export default http;
