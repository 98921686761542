import classNames from 'classnames/bind';
import styles from './LogoItem.module.scss';
import { Link } from 'react-router-dom';
import Image from '../Image/image';

const cx = classNames.bind(styles);

function LogoItem({ image, to, text = false, textColor = 'light', background = 'bg-light', className }) {
    if (to) {
        return (
            <Link to={to} className={cx('wrapper', 'clickable', background, { [className]: className })}>
                {image && <img className={cx('image')} src={image} />}
                <h3 className={cx(textColor)}>{text}</h3>
            </Link>
        );
    } else {
        return (
            <div className={cx('wrapper', background, { [className]: className })}>
                {image && <img className={cx('image')} src={image} />}
                <h3 className={cx(textColor)}>{text}</h3>
            </div>
        );
    }
}

export default LogoItem;
