import { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './CustomCombobox.module.scss';

const cx = classNames.bind(styles);

function CustomCombobox({ data, handleCategoryChange, text = '' }) {
    return (
        <div className={cx('wrapper')}>
            <select className={cx('content')} onChange={handleCategoryChange}>
                <option>{` -- ${text} -- `}</option>
                {data.map((item) => (
                    <option key={item.id} value={item.value}>
                        {item.description}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default CustomCombobox;
