import classNames from 'classnames/bind';
import styles from './Loading.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const cx = classNames.bind(styles);

function Loading() {
    return (
        <div className={cx('loader-container')}>
            <FontAwesomeIcon className={cx('loading-icon')} icon={faSpinner} spin></FontAwesomeIcon>
        </div>
    );
}

export default Loading;
