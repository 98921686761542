export const FILE_STORAGE = 'https://server.lacvietquality.com/api/files/Load?filename=';
export const DEFAULTPOSTIMG = 'baiviet.jpg';
export const LOGIN = 'auth/login';
export const REGISTER = 'auth/register';
export const FORGOT_PASSWORD = 'auth/forgotPassword';

//Post
const blog = 'PostsManagement/';
export const SEARCH_POST = blog + 'fetch/SearchPosts';
export const GET_ALL_POST = blog + 'fetch/GetAllPostsPageable';
export const GET_POST_BY_CATEGORY = blog + 'fetch/GetPostsByCategory';
export const GET_POSTS_BY_USERNAME = blog + 'fetch/GetPostsByUsername';
export const GET_POST = blog + 'fetch/GetPostById';
export const SAVE_POST = blog + 'SavePost';
export const UPDATE_POST = blog + 'UpdatePost';
export const DELETE_POST = blog + 'DeletePost';

//User
export const SEARCH_USER = 'UsersManagement/SearchUsers';
export const GET_USER_BY_USERNAME = 'UsersManagement/fetch/GetUserByUsername';
export const FOLLOW_USER = 'UsersManagement/FollowUser';
export const UNFOLLOW_USER = 'UsersManagement/UnfollowUser';

//file
export const UPLOADFILE = 'api/files/Upload';
export const LOADALLFILES = 'api/files/LoadAll';
export const DELETEFILE = 'api/files/delete';
