import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './swiper.css';

import 'react-slideshow-image/dist/styles.css';
import classNames from 'classnames/bind';
import styles from './Home.module.scss';

import images from '../../../asset/images';
import customers from '../../../asset/customers';
import htql from '../../../asset/HTQL';
import htqlattp from '../../../asset/HTQLATTP';
import otherServices from '../../../asset/otherServices';

import Image from '../../Image/image';
import LogoItem from '../../LogoItem/logoItem';
import nongNghiepSach from '../../../asset/nong-nghiep-sach';
import { useEffect, useState } from 'react';
import config from '../../../config';
import * as apiService from '../../../services/apiService';
import { useStore } from '../../../storage';

const cx = classNames.bind(styles);

function Home() {
    const [isHTQLFetching, setIsHTQLFetching] = useState(true);
    const [isInfoFetching, setIsInfoFetching] = useState(true);
    const [isHTQLATTPFetching, setIsHTQLATTPFetching] = useState(true);
    const [isNNSFetching, setIsNNSFetching] = useState(true);
    const [isOtherFetching, setIsOtherFetching] = useState(true);

    const [HTQLPost, setHTQLPost] = useState(true);
    const [HTQLATTPPost, setHTQLATTPPost] = useState(true);
    const [NNSPost, setNNSPost] = useState(true);
    const [OtherPost, setOtherPost] = useState(true);
    const [infoPost, setInfoPost] = useState();
    const [store, dispatch] = useStore();
    var loggedUser = Object.keys(store).length !== 0 ? store.loggedUser : false;

    useEffect(() => {
        document.title = 'Lạc Việt - Nâng tầm chất lượng việt';
        const fetchHTQL = async () => {
            setIsHTQLFetching(true);
            const fetchHTQLPost = await apiService.fetch(config.path.GET_POST_BY_CATEGORY, {
                params: {
                    category: 'htql',
                },
            });
            setHTQLPost(fetchHTQLPost);
            setIsHTQLFetching(false);
        };
        const fetchHTQLATTP = async () => {
            setIsHTQLATTPFetching(true);
            const fetchHTQLATTPPost = await apiService.fetch(config.path.GET_POST_BY_CATEGORY, {
                params: {
                    category: 'htqlAppt',
                },
            });
            setHTQLATTPPost(fetchHTQLATTPPost);
            setIsHTQLATTPFetching(false);
        };
        const fetchNNS = async () => {
            setIsNNSFetching(true);
            const fetchNNSPost = await apiService.fetch(config.path.GET_POST_BY_CATEGORY, {
                params: {
                    category: 'nongNghiepSach',
                },
            });
            setNNSPost(fetchNNSPost);
            setIsNNSFetching(false);
        };
        const fetchDichVu = async () => {
            setIsOtherFetching(true);
            const fetchDichVuPost = await apiService.fetch(config.path.GET_POST_BY_CATEGORY, {
                params: {
                    category: 'dichVu',
                },
            });
            setOtherPost(fetchDichVuPost);
            setIsOtherFetching(false);
        };
        const fetchInfo = async () => {
            setIsInfoFetching(true);
            const fetchInfoPost = await apiService.fetch(config.path.GET_POST_BY_CATEGORY, {
                params: {
                    category: 'tinTuc',
                },
            });

            setIsInfoFetching(false);
            setInfoPost(fetchInfoPost);
        };
        fetchHTQL();
        fetchNNS();
        fetchHTQLATTP();
        fetchDichVu();
        fetchInfo();
    }, []);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('block')}>
                <h2 className={cx('gioi-thieu')}>GIỚI THIỆU VỀ LẠC VIỆT</h2>
                <div className={cx('block-content')}>
                    <div className={cx('text-block')}>
                        <h4>Tầm Nhìn</h4>
                        <p>
                            Trở thành thương hiệu hàng đầu Việt Nam trong lĩnh vực năng suất và chất lượng, song song
                            phát triển vững mạnh về tổ chức và tiên tiến về công nghệ.
                        </p>
                        <h4>Sứ Mệnh</h4>
                        <p>
                            Chúng tôi cam kết hỗ trợ tối đa các tổ chức, doanh nghiệp đáp ứng đầy đủ các yêu cầu quản lý
                            nhà nước, nhu cầu thị trường và đồng hành cùng tổ chức, doanh nghiệp nâng tầm thương hiệu và
                            mở rộng thị trường.
                        </p>
                    </div>
                    <Image className={cx('img')} src={images.tamnhin1} alt="tam-nhin-su-mang" />
                    <Image className={cx('img')} src={images.tamnhin2} alt="tam-nhin-su-mang" />
                </div>
            </div>
            {!isHTQLFetching && (
                <div className={cx('block-color')}>
                    <h2>HỆ THỐNG QUẢN LÍ</h2>
                    <div className={cx('block-content-full')}>
                        {HTQLPost.map((post) => {
                            return (
                                <LogoItem
                                    key={post.id}
                                    className={cx('logo')}
                                    image={
                                        post.type === 'defaultPost'
                                            ? htql(post.thumbnail)
                                            : config.path.FILE_STORAGE + post.thumbnail
                                    }
                                    text={post.shortTitle}
                                    to={`/post/${post.transliterated}/${post.id}`}
                                    background="none"
                                />
                            );
                        })}
                        {loggedUser && (
                            <LogoItem
                                key={'add'}
                                className={cx('logo')}
                                image={images.plus}
                                to={`/write`}
                                background="none"
                            />
                        )}
                    </div>
                </div>
            )}
            {!isNNSFetching && (
                <div className={cx('block')}>
                    <h2>NÔNG NGHIỆP SẠCH - BỀN VỮNG</h2>
                    <div className={cx('block-content')}>
                        {NNSPost.map((post) => {
                            return (
                                <LogoItem
                                    key={post.id}
                                    className={cx('logo')}
                                    image={
                                        post.type === 'defaultPost'
                                            ? nongNghiepSach(post.thumbnail)
                                            : config.path.FILE_STORAGE + post.thumbnail
                                    }
                                    text={post.shortTitle}
                                    to={`/post/${post.transliterated}/${post.id}`}
                                    background="none"
                                />
                            );
                        })}
                        {loggedUser && (
                            <LogoItem
                                key={'add'}
                                className={cx('logo')}
                                image={images.plus}
                                to={`/write`}
                                background="none"
                            />
                        )}
                    </div>
                </div>
            )}
            {!isHTQLATTPFetching && (
                <div className={cx('block-color')}>
                    <h2>HỆ THỐNG QUẢN LÍ ATTP</h2>
                    <div className={cx('block-content-full')}>
                        {HTQLATTPPost.map((post) => {
                            return (
                                <LogoItem
                                    key={post.id}
                                    className={cx('logo')}
                                    image={
                                        post.type === 'defaultPost'
                                            ? htqlattp(post.thumbnail)
                                            : config.path.FILE_STORAGE + post.thumbnail
                                    }
                                    text={post.shortTitle}
                                    to={`/post/${post.transliterated}/${post.id}`}
                                    background="none"
                                />
                            );
                        })}
                        {loggedUser && (
                            <LogoItem
                                key={'add'}
                                className={cx('logo')}
                                image={images.plus}
                                to={`/write`}
                                background="none"
                            />
                        )}
                    </div>
                </div>
            )}
            {!isOtherFetching && (
                <div className={cx('block')}>
                    <h2>DỊCH VỤ KHÁC</h2>
                    <div className={cx('block-content')}>
                        {OtherPost.map((post) => {
                            return (
                                <LogoItem
                                    key={post.id}
                                    className={cx('logo')}
                                    image={
                                        post.type === 'defaultPost'
                                            ? otherServices(post.thumbnail)
                                            : config.path.FILE_STORAGE + post.thumbnail
                                    }
                                    text={post.shortTitle}
                                    to={`/post/${post.transliterated}/${post.id}`}
                                    background="none"
                                />
                            );
                        })}
                        {loggedUser && (
                            <LogoItem
                                key={'add'}
                                className={cx('logo')}
                                image={images.plus}
                                to={`/write`}
                                background="none"
                            />
                        )}
                    </div>
                </div>
            )}
            {!isInfoFetching && (
                <div className={cx('block')}>
                    <h2 className={cx('tin-tuc')}>TIN TỨC CHUYÊN NGÀNH</h2>
                    <div className={cx('block-content')}>
                        {infoPost.map((post) => {
                            return (
                                <LogoItem
                                    key={post.id}
                                    className={cx('logo')}
                                    image={config.path.FILE_STORAGE + post.thumbnail}
                                    text={post.shortTitle}
                                    to={`/post/${post.transliterated}/${post.id}`}
                                    background="none"
                                />
                            );
                        })}
                        {loggedUser && (
                            <LogoItem
                                key={'add'}
                                className={cx('logo')}
                                image={images.plus}
                                to={`/write`}
                                background="none"
                            />
                        )}
                    </div>
                </div>
            )}
            <div className={cx('block')}>
                <div className={cx('why-choose-me-block')}>
                    <Image
                        className={cx('why-choose-me-image')}
                        src={images.whyChooseMe}
                        alt="tai-sao-chon-chung-toi"
                    />
                    <div className={cx('why-choose-me-subblock')}>
                        <h3>Tại sao chọn chúng tôi ?</h3>
                        <div className={cx('sub-block')}>
                            <div className={cx('child-block')}>
                                <h4>Khả năng dịch vụ</h4>
                                <p>
                                    Lạc Việt cung cấp đa dạng dịch vụ cho doanh nghiệp về tư vấn xây dựng hệ thống quản
                                    lý, đào tạo, hướng dẫn áp dụng và đánh giá chứng nhận. Hợp chuẩn, Hợp quy, Kiểm
                                    nghiệm và Công bố lưu hành sản phẩm, Xây dựng đủ điều kiện sản xuất cho nhà máy…
                                    giúp doanh nghiệp quản lý thông qua 1 đầu mối dịch vụ.
                                </p>
                            </div>
                            <div className={cx('child-block')}>
                                <h4>Chi phí</h4>
                                <p>
                                    Lạc Việt hướng tới sự phát triển bền vững, đồng hành lâu dài cùng khách hàng trong
                                    quá trình sử dụng dịch vụ tư vấn và mang lại giá trị lâu bền cho doanh nghiệp.
                                </p>
                            </div>
                        </div>
                        <div className={cx('sub-block')}>
                            <div className={cx('child-block')}>
                                <h4>Thủ tục nhanh gọn</h4>
                                <p>Thủ tục hồ sơ đầy đủ, nhanh gọn, chính xác và tiết kiệm thời gian.</p>
                            </div>
                            <div className={cx('child-block')}>
                                <h4>Đội ngũ nhân viên</h4>
                                <p>Đội ngũ chuyên gia giàu kinh nghiệm, trẻ trung, năng động và tận tâm.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx('block')}>
                <h2>KHÁCH HÀNG TIÊU BIỂU</h2>
                <div className={cx('swiper-content')}>
                    <Swiper
                        slidesPerView="auto"
                        spaceBetween={50}
                        centeredSlides={true}
                        centeredSlidesBounds={true}
                        pagination={{
                            clickable: false,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <Image className={cx('logo')} src={customers.customer1} alt="customer" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Image className={cx('logo')} src={customers.customer2} alt="customer" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Image className={cx('logo')} src={customers.customer3} alt="customer" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Image className={cx('logo')} src={customers.customer4} alt="customer" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Image className={cx('logo')} src={customers.customer5} alt="customer" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Image className={cx('logo')} src={customers.customer6} alt="customer" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Image className={cx('logo')} src={customers.customer7} alt="customer" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Image className={cx('logo')} src={customers.customer8} alt="customer" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Image className={cx('logo')} src={customers.customer9} alt="customer" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Image className={cx('logo')} src={customers.customer10} alt="customer" />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default Home;
